export default {
  text: '#000000',
  primary: '#162852',
  secondary: '#55622c',
  tertiary: 'lightgrey',
  background: 'white',
  backgroundSecondary: '#65594f',
  light: '#FFF',
  dark: '#050707'
}
